import { SvgIcon } from '@mui/material';
import React from 'react';

function CreditIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '19pxpx', ...sx }} width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <g clipPath="url(#clip0_1760_211)">
        <path d="M14.7626 2.625H4.52638C3.55717 2.62612 2.62798 2.99962 1.94264 3.66357C1.2573 4.32752 0.871766 5.2277 0.870605 6.16667H18.4183C18.4172 5.2277 18.0317 4.32752 17.3463 3.66357C16.661 2.99962 15.7318 2.62612 14.7626 2.625V2.625Z" fill="#108AE2" />
        <path d="M0.870605 11.8333C0.871766 12.7722 1.2573 13.6724 1.94264 14.3363C2.62798 15.0003 3.55717 15.3738 4.52638 15.3749H14.7626C15.7318 15.3738 16.661 15.0003 17.3463 14.3363C18.0317 13.6724 18.4172 12.7722 18.4183 11.8333V7.58325H0.870605V11.8333ZM5.9887 11.4791C5.9887 11.6892 5.92437 11.8947 5.80386 12.0694C5.68335 12.2441 5.51207 12.3803 5.31166 12.4607C5.11126 12.5411 4.89075 12.5622 4.678 12.5212C4.46525 12.4802 4.26984 12.379 4.11645 12.2304C3.96307 12.0818 3.85862 11.8925 3.8163 11.6864C3.77398 11.4803 3.7957 11.2666 3.87871 11.0725C3.96172 10.8783 4.10229 10.7124 4.28265 10.5956C4.46301 10.4789 4.67505 10.4166 4.89196 10.4166C5.18283 10.4166 5.46179 10.5285 5.66747 10.7278C5.87315 10.927 5.9887 11.1973 5.9887 11.4791" fill="#108AE2" />
      </g>
      <rect x="0.88623" y="0.515625" width="17.5165" height="16.9688" stroke="#FFA5E0" strokeOpacity="0.12" strokeWidth="0.03125" />
      <defs>
        <clipPath id="clip0_1760_211">
          <rect x="0.870605" y="0.5" width="17.5477" height="17" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CreditIcon;
