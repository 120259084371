import { skin, SKINS } from '../../../../constants/skin';
import { theme } from '../../../../theme';
import { datonicsTheme } from '../../../../theme/datonicsTheme';

const colorDateLabels = skin === SKINS.IIQ
  ? theme.palette.primary.main
  : datonicsTheme.palette.primary.main;

export const OPTIONS = {
  interaction: {
    intersect: true,
  },
  indexAxis: 'y',
  parsing: {
    xAxisKey: 'composition',
    yAxisKey: 'composition',
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
      top: 20,
      bottom: 20,
    },
  },
  scales: {
    x: {
      ticks: {
        callback(value) {
          return `${value}%`;
        },
        max: 100,
        stepSize: 10,
      },
      suggestedMax: 100,
      stacked: true,
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: 9,
      stacked: true,
    },
  },
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: 'y',
        threshold: 100,
      },
      zoom: {
        wheel: {
          enabled: false,
          speed: 0.1,
        },
        pinch: {
          enabled: false,
        },
        mode: 'y',
      },
    },
    datalabels: {
      color: theme.palette.primary.orange,
      font: {
        size: 14,
        weight: 'bold',
      },
      anchor: 'end',
      align: 'end',
      formatter: (value, context) => {
        if (value?.index_value) {
          const maxY = context.chart.options.scales.y.max;
          const minY = context.chart.options.scales.y.min;
          if (context.dataIndex >= minY && context.dataIndex <= maxY) {
            return value?.index_value;
          }
        }
        return null;
      },
    },
    tooltip: {
      displayColors: false,
      yAlign: 'center',
      callbacks: {
        label: (context) => {
          const currentIndexValue = context?.raw?.index_value
            ? context.raw.index_value
            : context.raw.total;

          const total = context.dataset.data
            .reduce((acc, val) => acc + (val.index_value || val.total), 0);
          const percentage = `${((currentIndexValue / total) * 100).toFixed(2)}%`;

          const indexValue = context?.raw?.index_value
            ? `(index: ${currentIndexValue})`
            : `(index: ${currentIndexValue})`;

          return `Composition ${percentage} ${indexValue}`;
        },
      },
    },
    tooltips: {
      enabled: true,
      yAlign: 'top',
      callbacks: {
        label(tooltipItems, data) {
          return `${data.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel} %`;
        },
      },
    },
  },
};
export const ONE_PERSON = '1_persons';
export const IDS_NO_PERSON = '3_ids_no_person';
export const HOUSEHOLDS = '0_households';
export const IDS_NO_HOUSEHOLDS = '2_ids_no_households';

export const TITLES = {
  [ONE_PERSON]: 'Person',
  [IDS_NO_PERSON]: 'Device',
  [HOUSEHOLDS]: 'Household',
  [IDS_NO_HOUSEHOLDS]: 'Device',
};

export const PERSON = 'person';
export const DEVICE = 'device';
export const PERSON_EXPECTED = 'person_expected';
export const DEVICE_EXPECTED = 'device_expected';
