import { SvgIcon } from '@mui/material';
import React from 'react';

function StepperErrorIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '114px', ...sx }} width="114" height="113" viewBox="0 0 114 113" fill="none" {...props}>
      <ellipse cx="56.1797" cy="56.7969" rx="56" ry="56.5" fill="#108AE2" fillOpacity="0.05" />
      <ellipse cx="56.1797" cy="56.7969" rx="34" ry="33.5" fill="#108AE2" />
      <circle cx="56.1797" cy="56.7969" r="40.5" fill="#108AE2" fillOpacity="0.1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M54.3826 46.8615C54.8764 46.5835 55.4334 46.4375 56 46.4375C56.5666 46.4375 57.1236 46.5835 57.6174 46.8615C58.1111 47.1395 58.5248 47.54 58.8187 48.0244L58.8222 48.0303L67.5583 62.6146C67.8462 63.1131 67.9984 63.6784 68 64.254C68.0016 64.8296 67.8525 65.3957 67.5674 65.8958C67.2823 66.3959 66.8713 66.8126 66.3752 67.1045C65.8791 67.3965 65.3151 67.5534 64.7395 67.5597L64.7259 67.5599L47.2605 67.5598C46.6849 67.5535 46.1209 67.3965 45.6248 67.1045C45.1287 66.8126 44.7177 66.3959 44.4326 65.8958C44.1475 65.3957 43.9984 64.8296 44 64.254C44.0016 63.6784 44.1539 63.1132 44.4418 62.6147L44.4517 62.5975L53.1813 48.0244C53.4752 47.54 53.8889 47.1395 54.3826 46.8615ZM56 48.91C55.8584 48.91 55.7191 48.9465 55.5957 49.016C55.473 49.0851 55.3701 49.1844 55.2967 49.3045L46.5792 63.8578C46.5097 63.9807 46.4729 64.1196 46.4725 64.2609C46.4721 64.4048 46.5094 64.5463 46.5807 64.6714C46.6519 64.7964 46.7547 64.9006 46.8787 64.9736C47.0013 65.0456 47.1403 65.0848 47.2824 65.0873H64.7176C64.8597 65.0848 64.9987 65.0456 65.1213 64.9736C65.2453 64.9006 65.3481 64.7964 65.4193 64.6714C65.4906 64.5463 65.5279 64.4048 65.5275 64.2609C65.5271 64.1196 65.4903 63.9808 65.4209 63.8578L56.7047 49.3068C56.7042 49.306 56.7038 49.3053 56.7033 49.3046C56.6299 49.1844 56.527 49.0851 56.4043 49.016C56.2809 48.9465 56.1416 48.91 56 48.91Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M55.9999 52.7266C56.6827 52.7266 57.2362 53.2801 57.2362 53.9628V58.0837C57.2362 58.7665 56.6827 59.32 55.9999 59.32C55.3172 59.32 54.7637 58.7665 54.7637 58.0837V53.9628C54.7637 53.2801 55.3172 52.7266 55.9999 52.7266Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M54.7637 62.2011C54.7637 61.5183 55.3172 60.9648 55.9999 60.9648H56.0102C56.693 60.9648 57.2465 61.5183 57.2465 62.2011C57.2465 62.8839 56.693 63.4374 56.0102 63.4374H55.9999C55.3172 63.4374 54.7637 62.8839 54.7637 62.2011Z" fill="white" />
    </SvgIcon>
  );
}

export default StepperErrorIcon;
