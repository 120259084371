import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { getPartners } from './home-data';
import { DEFAULT_ERROR } from '../constants/errors';
import { SKINS, skin } from '../constants/skin';
import { INACTIVE_CAMPAIGN } from '../constants/campaign';
import { getUser, setUser } from './authentication';

const initialState = {
  data: [],
  customers: [],
  partners: [],
  partnersForModal: [],
  customersForCreateModal: [],
  roles: [],
  user: null,
  loading: false,
  loadingCreate: false,
  total: 0,
  page: 1,
};

export const getCustomers = createAsyncThunk(
  'users/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
        is_active: INACTIVE_CAMPAIGN,
      },
    });

    return data;
  },
);

export const getPartnersForModal = createAsyncThunk(
  'users/partners/for-modal',
  async (user_type) => {
    const { data } = await http.get('users/partners', {
      params: {
        user_type,
      },
    });

    return data;
  },
);

export const getCustomersForCreateModal = createAsyncThunk(
  'users/customers/create-modal',
  async (params) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params,
    });

    return data;
  },
);

export const getRoles = createAsyncThunk(
  'users/roles',
  async () => {
    const { data } = await http.get('roles/list');

    return data;
  },
);

export const getUsers = createAsyncThunk(
  'users/list',
  async ({ filters, ...params }) => {
    const url = skin === SKINS.DATONICS ? '/users/datonics' : '/users';

    const data = await http.get(url, { params: { ...params, ...filters } });

    return data;
  },
);

export const createUser = createAsyncThunk(
  'users/create',
  async ({ formData, filters }, { rejectWithValue, dispatch }) => {
    try {
      const url = skin === SKINS.DATONICS ? '/users/datonics' : '/users';

      const data = await http.post(url, formData);

      dispatch(getUsers({
        page: 1,
        perPage: 8,
        filters,
      }));
      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue('error');
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async ({ id, filters }, { rejectWithValue, dispatch }) => {
    try {
      const url = skin === SKINS.DATONICS ? '/users/datonics' : '/users';
      const data = await http.delete(`${url}/${id}`);
      toast.success('User successfully deleted');

      dispatch(getUsers({
        page: 1,
        perPage: 8,
        filters,
      }));
      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue('error');
    }
  },
);

export const handleHideBussinessNames = createAsyncThunk(
  'users/handleHideBussinessNames',
  async ({
    id, formData,
  }, { rejectWithValue }) => {
    try {
      const url = skin === SKINS.DATONICS ? '/users/datonics' : '/users';

      const res = await http.patch(`${url}/${id}`, formData);

      toast.success('Hide bussiness names successfully updated');

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue('error');
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/update',
  async ({
    id, formData, filters, mainUserId,
  }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();

      const url = skin === SKINS.DATONICS ? '/users/datonics' : '/users';

      const res = await http.patch(`${url}/${id}`, formData);

      toast.success('User successfully updated');

      if (id === mainUserId) {
        const { permission_name, super_admin, role } = state.auth.user;

        const newUserState = {
          ...res.data,
          permission_name,
          super_admin,
          role,
        };

        dispatch(setUser(newUserState));
      }

      dispatch(getUsers({
        page: 1,
        perPage: 8,
        filters,
      }));
      dispatch(getPartners());

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue('error');
    }
  },
);

export const permissionSlice = createSlice({
  name: 'permissions',
  reducers: {
    setNewUser: (state, { payload }) => {
      state.user = payload;
    },
    clearUserReduce: () => initialState,
  },
  initialState,
  extraReducers: {
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload;
    },
    [getCustomersForCreateModal.fulfilled]: (state, { payload }) => {
      state.customersForCreateModal = payload;
    },
    [getPartnersForModal.fulfilled]: (state, { payload }) => {
      state.partnersForModal = payload;
    },
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.data = payload.data.res;
      state.total = payload.data.total;
      state.page = arg.page;
      state.loading = false;
    },
    [getPartners.pending]: (state) => {
      state.loading = true;
    },
    [getPartners.fulfilled]: (state) => {
      state.loading = false;
    },
    [createUser.pending]: (state) => {
      state.loadingCreate = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.user = payload.data;
      state.data = [...state.data, payload.data];
      state.loadingCreate = false;
    },
    // TODO: remove after user module fully tested
    // [updateUser.pending]: (state) => {
    //   state.loadingCreate = true;
    // },
    // [updateUser.fulfilled]: (state, { meta: { arg }, payload }) => {
    //   const user = state.data.find((item) => item.id === arg.id);
    //   const updatedUser = {
    //     ...user,
    //     ...payload,
    //     partner_id: payload.partner?.id ? payload.partner.id : payload.partner,
    //     first_name: payload.first_name,
    //     last_name: payload.last_name,
    //   };

    //   state.loadingCreate = false;
    //   state.data = state.data.map((user) => (user.id === arg.id
    //     ? updatedUser
    //     : user));

    //   toast.success('User successfully updated');
    // },
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state) => {
      state.loading = false;
    },
    [createUser.rejected]: (state) => {
      state.loadingCreate = false;
    },
  },
});

export const {
  setNewUser, clearUserReduce,
} = permissionSlice.actions;
export default permissionSlice.reducer;
