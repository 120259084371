import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { actionCreator } from '../utility/action';
import { ACTION_TYPES } from '../constants';
import { toastError } from '../utility/toast';

const initialState = {
  table: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    page: 1,
  },
  tablePreview: {
    columns: [],
    list: [],
    total: 0,
  },
  customers: [],
  audiences: [],
  formats: [],
  databases: [],
  schemas: [],
  tables: [],
  formatDetails: null,
  formatDetailsLoading: false,
  isLoading: false,
  isDataLoaded: false,
  isTablePreviewLoading: false,
};

export const createDataMapping = createAsyncThunk(
  'data-mapping/create',
  async ({ body, callback }) => {
    const { data } = await http.post('/data-mapping', body);
    callback();

    return data;
  },
);

export const updateDataMapping = createAsyncThunk(
  'data-mapping/update',
  async ({ callback, ...body }) => {
    const { data } = await http.patch('/data-mapping', body);
    callback();
    toast.success('Data Mapping is updated!');

    return data;
  },
);

export const getDataMapping = createAsyncThunk(
  'data-mapping',
  async ({
    page, perPage, filters,
  }) => {
    const { data } = await http.get('/data-mapping', {
      params: {
        page,
        per_page: perPage,
        ...{
          ...filters,
        },
      },
    });

    return data;
  },
);

export const uploadSnowflakeFile = createAsyncThunk(
  'data-mapping/upload',
  async (values, { rejectWithValue }) => {
    try {
      const res = await http.post('offline-data/snowflake-upload', values);

      return res.data?.filename;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue('error');
    }
  },
);

export const deleteDataMapping = actionCreator({
  id: '/data-mapping/delete',
  route: '/data-mapping',
  actionType: ACTION_TYPES.delete,
});

export const getAudiences = actionCreator({
  id: '/data-mapping/audiences',
  route: '/segments',
  actionType: ACTION_TYPES.get,
});

export const getCustomers = createAsyncThunk(
  'data-mapping/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
      },
    });

    return data;
  },
);

export const getDataMappingFormatDetails = createAsyncThunk(
  'data-mapping/format-details',
  async (params) => {
    try {
      const res = await http.get('offline-data/format-details', { params });

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      toast.error(errorMessage);
    }
  },
);

export const getDatabases = createAsyncThunk(
  'data-mapping/databases',
  async () => {
    const res = await axios.get('/api/snowpark/database');

    return res.data;
  },
);

export const getSchemas = createAsyncThunk(
  'data-mapping/schemas',
  async (id) => {
    const res = await axios.get('/api/snowpark/schemas', { params: { database: id } });

    return res.data;
  },
);

export const getTables = createAsyncThunk(
  'data-mapping/tables',
  async (id) => {
    const res = await axios.get('/api/snowpark/tables', { params: { schema: id } });

    return res.data;
  },
);

export const getTablePreview = createAsyncThunk(
  'data-mapping/table-preview',
  async (table) => {
    const res = await axios.get('/api/snowpark/table-preview', { params: { table } });

    return res.data;
  },
);

export const getFormats = createAsyncThunk(
  'data-mapping/formats',
  async (params) => {
    try {
      const res = await http.get('offline-data/formats', { params });

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toastError(errorMessage);
      }
    }
  },
);

export const segmentsSlice = createSlice({
  name: 'data-mapping',
  reducers: {
    clearDataMappingReducer: () => initialState,
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    resetFormatDetails: (state, { payload }) => {
      state.formatDetails = payload;
    },
  },
  initialState,
  extraReducers: {
    [getDataMapping.pending]: (state) => {
      state.table.loading = true;
    },
    [getDataMapping.rejected]: (state) => {
      state.table.loading = false;
    },
    [getDataMapping.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.table = {
        ...state.table,
        loading: false,
        page: arg.page || state.table.page,
        data: payload[0],
        total: payload[1],
      };
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getDataMappingFormatDetails.fulfilled]: (state, { payload }) => {
      state.formatDetails = payload;
      state.formatDetailsLoading = false;
    },
    [getDataMappingFormatDetails.pending]: (state) => {
      state.formatDetailsLoading = true;
    },
    [getDataMappingFormatDetails.rejected]: (state) => {
      state.formatDetailsLoading = false;
    },
    [getAudiences.fulfilled]: (state, { payload }) => {
      state.audiences = payload.data;
    },
    [getDatabases.fulfilled]: (state, { payload }) => {
      state.databases = payload;
    },
    [getSchemas.fulfilled]: (state, { payload }) => {
      state.schemas = payload;
    },
    [getTables.fulfilled]: (state, { payload }) => {
      state.tables = payload;
    },
    [getFormats.fulfilled]: (state, { payload }) => {
      state.formats = payload;
    },
    [getTablePreview.pending]: (state) => {
      state.isTablePreviewLoading = true;
    },
    [getTablePreview.rejected]: (state) => {
      state.isTablePreviewLoading = false;
    },
    [getTablePreview.fulfilled]: (state, { payload }) => {
      state.tablePreview = payload;
      state.isTablePreviewLoading = false;
    },
  },
});

export const {
  clearDataMappingReducer, setCustomers, resetFormatDetails,
} = segmentsSlice.actions;

export default segmentsSlice.reducer;
