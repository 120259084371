import { SvgIcon } from '@mui/material';
import React from 'react';

function StopCampaign({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '19px', ...sx }} width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <path d="M12.5021 0.64624C5.95185 0.64624 0.648438 5.94965 0.648438 12.4999C0.648438 19.0501 5.95185 24.3536 12.5021 24.3536C19.0523 24.3536 24.3558 19.0501 24.3558 12.4999C24.3558 5.94965 19.0523 0.64624 12.5021 0.64624ZM17.1909 15.8892C17.1909 16.6092 16.6114 17.1887 15.8914 17.1887H9.11283C8.39283 17.1887 7.81332 16.6092 7.81332 15.8892V9.11063C7.81332 8.39063 8.39283 7.81112 9.11283 7.81112H15.8914C16.6114 7.81112 17.1909 8.39063 17.1909 9.11063V15.8892Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default StopCampaign;
