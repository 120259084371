export const Overview = 'Overview';
export const Demographics = 'Demographics';
export const Interests = 'Interest';
export const Lifestyle = 'Lifestyle';
export const ShoppingPreferences = 'Shopping Preferences';
export const B2B = 'B2B';
export const Financials = 'Financials';
export const Technology = 'Technology';
export const TechEnthusiasts = 'Tech Enthusiasts';
export const Telecommunications = 'Telecommunications';

export const TITLES = {
  '1_persons': 'Persons',
  '3_ids_no_person': 'IDs_No_Person',
  '0_households': 'Households',
  '2_ids_no_households': 'IDs_No_Household',
};

export const AUDIENCE_DEFAULT_COUNT = 3;
