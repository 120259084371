import { SvgIcon } from '@mui/material';
import React from 'react';

function InEnrichmentActiveIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '114px', ...sx }} width="114" height="113" viewBox="0 0 114 113" fill="none" {...props}>
      <ellipse cx="56.9971" cy="56.7969" rx="56" ry="56.5" fill="#108AE2" fillOpacity="0.05" />
      <ellipse cx="56.9971" cy="56.7969" rx="34" ry="33.5" fill="#108AE2" />
      <circle cx="56.9971" cy="56.7969" r="40.5" fill="#108AE2" fillOpacity="0.1" />
      <path d="M61.2471 56.7969C61.2471 59.0626 59.3569 60.9219 56.9971 60.9219C54.6372 60.9219 52.7471 59.0626 52.7471 56.7969C52.7471 54.5312 54.6372 52.6719 56.9971 52.6719C59.3569 52.6719 61.2471 54.5312 61.2471 56.7969Z" fill="white" stroke="white" />
      <path d="M53.8302 59.8789L48.2886 65.2747" stroke="white" strokeLinejoin="round" />
      <path d="M54.6221 54.4844L47.4971 47.5469" stroke="white" strokeLinejoin="round" />
      <path d="M67.5804 64.5052C67.5804 65.9195 66.3992 67.0885 64.9137 67.0885C63.4283 67.0885 62.2471 65.9195 62.2471 64.5052C62.2471 63.091 63.4283 61.9219 64.9137 61.9219C66.3992 61.9219 67.5804 63.091 67.5804 64.5052Z" fill="white" stroke="white" />
      <path d="M72.3304 56.7982C72.3304 58.2124 71.1491 59.3815 69.6637 59.3815C68.1783 59.3815 66.9971 58.2124 66.9971 56.7982C66.9971 55.3839 68.1783 54.2148 69.6637 54.2148C71.1491 54.2148 72.3304 55.3839 72.3304 56.7982Z" fill="white" stroke="white" />
      <path d="M67.2889 56.7969H60.9556" stroke="white" strokeLinejoin="round" />
      <path d="M60.1636 59.8789L63.3302 62.9622" stroke="white" strokeLinejoin="round" />
      <path d="M72.3306 46.0039C72.3306 48.2696 70.4404 50.1289 68.0806 50.1289C65.7207 50.1289 63.8306 48.2696 63.8306 46.0039C63.8306 43.7382 65.7207 41.8789 68.0806 41.8789C70.4404 41.8789 72.3306 43.7382 72.3306 46.0039Z" fill="white" stroke="white" />
      <path d="M64.9136 49.0898L60.1636 53.7148" stroke="white" strokeLinejoin="round" />
      <path d="M48.5804 46.0052C48.5804 47.4195 47.3991 48.5885 45.9137 48.5885C44.4283 48.5885 43.2471 47.4195 43.2471 46.0052C43.2471 44.591 44.4283 43.4219 45.9137 43.4219C47.3991 43.4219 48.5804 44.591 48.5804 46.0052Z" fill="white" stroke="white" />
      <path d="M50.1636 67.5898C50.1636 69.8555 48.2734 71.7148 45.9136 71.7148C43.5537 71.7148 41.6636 69.8555 41.6636 67.5898C41.6636 65.3242 43.5537 63.4648 45.9136 63.4648C48.2734 63.4648 50.1636 65.3242 50.1636 67.5898Z" fill="white" stroke="white" />

    </SvgIcon>
  );
}

export default InEnrichmentActiveIcon;
