import { SvgIcon } from '@mui/material';
import React from 'react';

function RocketNoDataIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '169px', ...sx }} width="169" height="155" viewBox="0 0 169 155" fill="none" {...props}>
      <path d="M124.938 73.9062H77.9062" stroke="#D3D0D9" strokeWidth="6.71875" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M124.938 80.625H77.9062" stroke="#D3D0D9" strokeWidth="6.71875" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M102.005 138.496C101.775 137.615 100.98 137 100.069 137H21.9306C21.0201 137 20.2245 137.615 19.9951 138.496L17.6519 147.496C17.3221 148.763 18.2782 150 19.5874 150H102.413C103.722 150 104.678 148.763 104.348 147.496L102.005 138.496Z" fill="#EBE9F1" />
      <path d="M4 151.172H165.25" stroke="#D3D0D9" strokeWidth="6.71875" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M146.453 0H123.578C122.474 0 121.578 0.895429 121.578 2V149.172C121.578 150.276 122.474 151.172 123.578 151.172H146.453C147.558 151.172 148.453 150.276 148.453 149.172V2C148.453 0.895431 147.558 0 146.453 0Z" fill="#D3D0D9" />
      <path d="M139.734 10.0781H130.297C129.192 10.0781 128.297 10.9736 128.297 12.0781V31.5938C128.297 32.6983 129.192 33.5938 130.297 33.5938H139.734C140.839 33.5938 141.734 32.6983 141.734 31.5938V12.0781C141.734 10.9736 140.839 10.0781 139.734 10.0781Z" fill="#F6F7F9" />
      <path d="M139.734 47.0312H130.297C129.192 47.0312 128.297 47.9267 128.297 49.0312V68.5469C128.297 69.6514 129.192 70.5469 130.297 70.5469H139.734C140.839 70.5469 141.734 69.6514 141.734 68.5469V49.0312C141.734 47.9267 140.839 47.0312 139.734 47.0312Z" fill="#F6F7F9" />
      <path d="M139.734 83.9844H130.297C129.192 83.9844 128.297 84.8798 128.297 85.9844V105.5C128.297 106.605 129.192 107.5 130.297 107.5H139.734C140.839 107.5 141.734 106.605 141.734 105.5V85.9844C141.734 84.8798 140.839 83.9844 139.734 83.9844Z" fill="#F6F7F9" />
      <path d="M139.734 120.938H130.297C129.192 120.938 128.297 121.833 128.297 122.937V142.453C128.297 143.558 129.192 144.453 130.297 144.453H139.734C140.839 144.453 141.734 143.558 141.734 142.453V122.938C141.734 121.833 140.839 120.938 139.734 120.938Z" fill="#F6F7F9" />
      <path d="M42.1654 95.6253C42.0671 93.5613 39.6053 92.5493 38.0838 93.9475L29.0412 102.257C26.6051 104.345 25.5611 107.129 25.2131 110.261L23.4576 133.082C23.3118 134.978 25.2962 136.302 26.9907 135.441L43.6578 126.966L42.1654 95.6253ZM80.1993 90.4243L93.0758 102.257C95.5119 104.345 96.5559 107.129 96.9039 110.261L98.6594 133.082C98.8052 134.978 96.8208 136.302 95.1263 135.441L78.4592 126.966" fill="#C42A8F" />
      <path d="M78.4655 126.966C81.2496 120.702 87.5138 104.693 87.5138 84.1602C87.5138 46.0656 72.4322 20.0789 64.5436 12.1215C60.7178 8.26221 57.5833 12.1215 57.5833 12.1215C57.5833 12.1215 34.6157 35.0903 34.6157 83.4642C34.6157 109.565 40.88 121.05 43.6641 126.966H78.4655Z" fill="url(#paint0_linear_277_20606)" />
      <path d="M61.0618 74.7636C66.8278 74.7636 71.5022 70.0893 71.5022 64.3232C71.5022 58.5571 66.8278 53.8828 61.0618 53.8828C55.2957 53.8828 50.6213 58.5571 50.6213 64.3232C50.6213 70.0893 55.2957 74.7636 61.0618 74.7636Z" fill="#F6F7F9" />
      <path d="M64.7311 133.931C64.6267 135.879 63.0162 137.406 61.0648 137.406C59.1134 137.406 57.5029 135.879 57.3985 133.931L55.4966 98.4283C55.4966 95.6442 57.9327 92.8601 61.0648 92.8601C63.8489 92.8601 66.633 95.2962 66.633 98.4283L64.7311 133.931Z" fill="#C42A8F" />
      <defs>
        <linearGradient id="paint0_linear_277_20606" x1="61.0634" y1="1.68092" x2="61.0634" y2="165.247" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EC71C2" />
          <stop offset="1" stopColor="#108AE2" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default RocketNoDataIcon;
