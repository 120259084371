import { SvgIcon } from '@mui/material';
import React from 'react';

function TimeIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '15px', ...sx }} width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M7 0.5C5.71442 0.5 4.45771 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875702 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73191 13.3751C6.99279 13.6259 8.29972 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4981 5.27666 12.8127 3.62445 11.5941 2.40586C10.3756 1.18728 8.72334 0.501864 7 0.5ZM7 11.875C6.03582 11.875 5.09329 11.5891 4.2916 11.0534C3.48991 10.5177 2.86507 9.75637 2.49609 8.86558C2.12711 7.97479 2.03057 6.99459 2.21867 6.04893C2.40678 5.10328 2.87108 4.23463 3.55286 3.55285C4.23464 2.87107 5.10328 2.40677 6.04894 2.21867C6.99459 2.03057 7.97479 2.12711 8.86558 2.49609C9.75637 2.86506 10.5177 3.4899 11.0534 4.29159C11.5891 5.09328 11.875 6.03582 11.875 7C11.8736 8.29249 11.3595 9.53163 10.4456 10.4456C9.53163 11.3595 8.29249 11.8736 7 11.875Z" fill="#C1D6E4" />
      <path d="M6.18757 6.48776L4.88757 7.30026C4.7971 7.3569 4.71868 7.43081 4.65679 7.51776C4.59489 7.60472 4.55074 7.70302 4.52685 7.80704C4.50295 7.91107 4.49979 8.01878 4.51754 8.12403C4.53528 8.22927 4.57359 8.32999 4.63028 8.42043C4.68691 8.5109 4.76082 8.58932 4.84778 8.65121C4.93473 8.7131 5.03303 8.75726 5.13705 8.78115C5.24108 8.80505 5.34879 8.80821 5.45404 8.79046C5.55929 8.77272 5.66001 8.73441 5.75044 8.67772L7.30448 7.70272C7.46041 7.60502 7.58887 7.4692 7.67776 7.3081C7.76666 7.14699 7.81305 6.96589 7.81257 6.78189V4.70947C7.81257 4.49398 7.72696 4.28732 7.57459 4.13495C7.42222 3.98258 7.21556 3.89697 7.00007 3.89697C6.78458 3.89697 6.57792 3.98258 6.42554 4.13495C6.27317 4.28732 6.18757 4.49398 6.18757 4.70947V6.48776Z" fill="#C1D6E4" />
    </SvgIcon>
  );
}

export default TimeIcon;
